<template>
  <l-table-container>
    <template v-slot:search>
      <a-form ref="formRef" :model="search.data" :labelCol="{ style: { width: '100px' } }">
        <l-search-container @search="onSearch" @reset="onReset">
          <a-col v-bind="searchCol">
            <a-form-item label="日志类型" name="logType">
              <a-select v-model:value="search.data.logType" placeholder="请选择日志类型" allowClear>
                <a-select-option :value="item.value" v-for="item in gmClubOprLogTypes" :key="item.value">{{ item.label }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="searchCol">
            <a-form-item label="俱乐部ID" name="clubId">
              <a-input v-model:value.number="search.data.clubId" placeholder="请输入俱乐部ID" autocomplete="off" allowClear />
            </a-form-item>
          </a-col>
          <a-col v-bind="searchCol">
            <a-form-item label="游戏ID" name="maskId">
              <a-input v-model:value.number="search.data.maskId" placeholder="请输入游戏ID" autocomplete="off" allowClear />
            </a-form-item>
          </a-col>
          <a-col v-bind="searchCol">
            <a-form-item label="userId" name="userId">
              <a-input v-model:value.number="search.data.userId" placeholder="请输入userId" autocomplete="off" allowClear />
            </a-form-item>
          </a-col>
          <a-col v-bind="searchCol">
            <a-form-item label="操作时间">
              <a-row type="flex" :gutter="10" :wrap="false">
                <a-col :flex="1">
                  <a-date-picker show-time v-model:value="search.data.startTime" inputReadOnly placeholder="开始时间" value-format="YYYY-MM-DD HH:mm:ss" style="display: block" />
                </a-col>

                <a-col flex="20px"> <div style="margin-top: 5px">~</div> </a-col>

                <a-col :flex="1">
                  <a-date-picker show-time v-model:value="search.data.endTime" inputReadOnly placeholder="结束时间" value-format="YYYY-MM-DD HH:mm:ss" style="display: block" />
                </a-col>
              </a-row>
            </a-form-item>
          </a-col>
        </l-search-container>
      </a-form>
    </template>

    <a-spin :spinning="loading">
      <a-list :grid="{ gutter: 10, xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 3, xxxl: 3 }" :data-source="data">
        <template #renderItem="{ item }">
          <a-list-item>
            <a-card :title="getLabelByValue(gmClubOprLogTypes, item.logType)" hoverable :body-style="{ padding: '5px 10px' }" :head-style="{ padding: '5px 10px' }">
              <a-descriptions title="" size="small" :label-style="{ fontWeight: 'bold' }">
                <a-descriptions-item label="俱乐部">{{ item.clubId }} -- {{ item.clubName }}</a-descriptions-item>
                <a-descriptions-item label="被操作人">{{ item.maskId }} -- {{ item.nickName }} </a-descriptions-item>
                <a-descriptions-item label="操作内容">{{ item.content }} </a-descriptions-item>
                <a-descriptions-item label="操作人">{{ item.oprUsername }}</a-descriptions-item>
                <a-descriptions-item label="操作时间">{{ item.createdAt }}</a-descriptions-item>
              </a-descriptions>
            </a-card>
          </a-list-item>
        </template>
      </a-list>

      <div style="text-align: right">
        <a-pagination v-model:current="pagination.current" size="small" v-model:page-size="pagination.pageSize" :total="pagination.total" :show-total="pagination.showTotal" @change="handleTableChange" />
      </div>
    </a-spin>

    <!-- <l-table @reload="onSearch" :columns="columns" :data-source="data" :pagination="pagination" @change="handleTableChange" row-key="id" :loading="loading">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'icon'">
          <a-avatar :size="30" :src="record.icon"> </a-avatar>
        </template>

        <template v-if="column.dataIndex === 'logType'">
          {{ getLabelByValue(gmClubOprLogTypes, record.logType) }}
        </template>

        <template v-if="column.dataIndex === 'identity'">
          {{ getLabelByValue(clubMemberIdentitys, record.identity) }}
        </template>
      </template>
    </l-table> -->
  </l-table-container>
</template>

<script lang="ts">
import http from "@/common/http";
import { gmClubOprLogTypes, getLabelByValue, clubMemberIdentitys } from "@/common/head";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { pagConfig, searchCol } from "@/config/app";

const columns = [
  // {
  //   title: "ID",
  //   width: 80,
  //   dataIndex: "id",
  //   fixed: "left",
  // },

  {
    title: "日志类型",
    width: 100,
    dataIndex: "logType",

    ellipsis: true,
  },

  {
    title: "操作内容",
    width: 250,
    dataIndex: "content",
    ellipsis: true,
  },

  {
    title: "俱乐部ID",
    width: 80,
    dataIndex: "clubId",
    ellipsis: true,
  },

  {
    title: "俱乐部名称",
    width: 120,
    dataIndex: "clubName",
    ellipsis: true,
    // fixed: "left",
  },

  {
    title: "userID",
    width: 100,
    dataIndex: "userId",
    ellipsis: true,
  },

  {
    title: "游戏ID",
    width: 100,
    dataIndex: "maskId",
    ellipsis: true,
  },

  {
    title: "昵称",
    width: 160,
    dataIndex: "nickName",
    ellipsis: true,
  },

  {
    title: "身份",
    width: 100,
    dataIndex: "identity",
    ellipsis: true,
  },

  {
    title: "头像",
    width: 70,
    dataIndex: "icon",
    ellipsis: true,
  },

  {
    title: "等级",
    width: 70,
    dataIndex: "level",
    ellipsis: true,
  },

  {
    title: "关联的规则ID",
    width: 110,
    dataIndex: "ruleId",
    ellipsis: true,
  },

  {
    title: "操作人",
    width: 140,
    dataIndex: "oprUsername",
    ellipsis: true,
  },

  {
    title: "操作时间",
    width: 180,
    dataIndex: "createdAt",
    ellipsis: true,
  },

  // {
  //   title: "",
  //   key: "operation",
  //   align: "center",
  //   dataIndex: "operation",
  //   fixed: "right",
  //   width: 180,
  //   slots: { customRender: "action" },
  // },
];

export default defineComponent({
  components: {
    // Edit,
  },
  setup() {
    //搜索绑定数据
    const search = reactive({
      //是否显示有的搜索框
      showAll: false,

      //搜索数据
      data: {
        maskId: undefined,
        userId: undefined,
        clubId: undefined,
        opertionMaskId: undefined,
        opertionUserId: undefined,
        startTime: undefined,
        endTime: undefined,
        logType: undefined,
        // nickName: "",
      },

      //点击搜索后的搜索数据
      relData: {},
    });

    //表格加载中
    const loading = ref(true);

    const pagination = reactive(pagConfig);

    const data = ref([]);

    const formRef = ref();

    //获取列表
    const getList = async () => {
      loading.value = true;
      const result = await http.get("gm_club_opr_log", {
        page: pagination.current,
        page_size: pagination.pageSize,
        ...search.relData,
      });

      data.value = result.data.data;
      pagination.total = result.data.count;

      loading.value = false;
    };

    //监听页码切换
    // const handleTableChange = (pag: PaginationProps) => {
    //   pagination.current = pag?.current;
    //   pagination.pageSize = pag?.pageSize;
    //   getList();
    // };

    const handleTableChange = (pag: number, pageSize: number) => {
      pagination.current = pag;
      pagination.pageSize = pageSize;
      getList();
    };
    //点击搜索按钮
    const onSearch = () => {
      pagination.current = 1;
      search.relData = search.data;
      getList();
    };

    //点击重置按钮
    const onReset = () => {
      formRef.value.resetFields();
      search.data.startTime = undefined;
      search.data.endTime = undefined;
      onSearch();
    };

    onMounted(() => {
      getList();
    });

    return {
      data,
      columns,
      search,
      pagination,
      formRef,
      loading,
      handleTableChange,
      onSearch,
      onReset,
      searchCol,
      getLabelByValue,
      gmClubOprLogTypes,
      clubMemberIdentitys,
    };
  },
});
</script>
