
import http from "@/common/http";
import { gmClubOprLogTypes, getLabelByValue, clubMemberIdentitys } from "@/common/head";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { pagConfig, searchCol } from "@/config/app";

const columns = [
  // {
  //   title: "ID",
  //   width: 80,
  //   dataIndex: "id",
  //   fixed: "left",
  // },

  {
    title: "日志类型",
    width: 100,
    dataIndex: "logType",

    ellipsis: true,
  },

  {
    title: "操作内容",
    width: 250,
    dataIndex: "content",
    ellipsis: true,
  },

  {
    title: "俱乐部ID",
    width: 80,
    dataIndex: "clubId",
    ellipsis: true,
  },

  {
    title: "俱乐部名称",
    width: 120,
    dataIndex: "clubName",
    ellipsis: true,
    // fixed: "left",
  },

  {
    title: "userID",
    width: 100,
    dataIndex: "userId",
    ellipsis: true,
  },

  {
    title: "游戏ID",
    width: 100,
    dataIndex: "maskId",
    ellipsis: true,
  },

  {
    title: "昵称",
    width: 160,
    dataIndex: "nickName",
    ellipsis: true,
  },

  {
    title: "身份",
    width: 100,
    dataIndex: "identity",
    ellipsis: true,
  },

  {
    title: "头像",
    width: 70,
    dataIndex: "icon",
    ellipsis: true,
  },

  {
    title: "等级",
    width: 70,
    dataIndex: "level",
    ellipsis: true,
  },

  {
    title: "关联的规则ID",
    width: 110,
    dataIndex: "ruleId",
    ellipsis: true,
  },

  {
    title: "操作人",
    width: 140,
    dataIndex: "oprUsername",
    ellipsis: true,
  },

  {
    title: "操作时间",
    width: 180,
    dataIndex: "createdAt",
    ellipsis: true,
  },

  // {
  //   title: "",
  //   key: "operation",
  //   align: "center",
  //   dataIndex: "operation",
  //   fixed: "right",
  //   width: 180,
  //   slots: { customRender: "action" },
  // },
];

export default defineComponent({
  components: {
    // Edit,
  },
  setup() {
    //搜索绑定数据
    const search = reactive({
      //是否显示有的搜索框
      showAll: false,

      //搜索数据
      data: {
        maskId: undefined,
        userId: undefined,
        clubId: undefined,
        opertionMaskId: undefined,
        opertionUserId: undefined,
        startTime: undefined,
        endTime: undefined,
        logType: undefined,
        // nickName: "",
      },

      //点击搜索后的搜索数据
      relData: {},
    });

    //表格加载中
    const loading = ref(true);

    const pagination = reactive(pagConfig);

    const data = ref([]);

    const formRef = ref();

    //获取列表
    const getList = async () => {
      loading.value = true;
      const result = await http.get("gm_club_opr_log", {
        page: pagination.current,
        page_size: pagination.pageSize,
        ...search.relData,
      });

      data.value = result.data.data;
      pagination.total = result.data.count;

      loading.value = false;
    };

    //监听页码切换
    // const handleTableChange = (pag: PaginationProps) => {
    //   pagination.current = pag?.current;
    //   pagination.pageSize = pag?.pageSize;
    //   getList();
    // };

    const handleTableChange = (pag: number, pageSize: number) => {
      pagination.current = pag;
      pagination.pageSize = pageSize;
      getList();
    };
    //点击搜索按钮
    const onSearch = () => {
      pagination.current = 1;
      search.relData = search.data;
      getList();
    };

    //点击重置按钮
    const onReset = () => {
      formRef.value.resetFields();
      search.data.startTime = undefined;
      search.data.endTime = undefined;
      onSearch();
    };

    onMounted(() => {
      getList();
    });

    return {
      data,
      columns,
      search,
      pagination,
      formRef,
      loading,
      handleTableChange,
      onSearch,
      onReset,
      searchCol,
      getLabelByValue,
      gmClubOprLogTypes,
      clubMemberIdentitys,
    };
  },
});
